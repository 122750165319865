import React, { useEffect, useRef, useState } from "react";
import { useAuth } from "../../nux/niam/auth";
import NotLoggedIn from "./NotLoggedIn";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { List } from "@mui/material";
import { PersonDetails } from "./components/PersonDetails";
import { ConsentListItem } from "./components/ConsentListItem";
import { useAccountInsightClient } from "../../client/AccountInsightClient";
import { CreatingNewAccountInsightSessionDialog } from "./components/CreatingNewAccountInsightSessionDialog";
import { useNuxDialog } from "../../nux/components/dialogs/NuxDialog";
import {
    refreshSearch,
    searchForCreatedBy,
    searchForSelectedStatus,
    searchForText,
    SearchParameters,
    SearchStatus,
    updateCurrentPage,
    updateSessionsPerPage,
    updateWithCollectorSessionResponse,
} from "./SearchParameters";
import { Status } from "../../client/AccountInsightsTypes";
import { SearchBar } from "./components/SearchBar";
import { useNuxMessages } from "../../nux/components/messages/NuxMessages";
import { ShowVisualisationLinkDialog } from "./components/ShowVisualisationLinkDialog";
import { SearchPagination } from "./components/SearchPagination";

const MainPage = () => {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const { authenticated, accessToken, user } = useAuth();
    const { showMessage } = useNuxMessages();
    const {
        allAccountInsightSessions,
        accountInsightInitiate,
        removeSession,
        getAccountInsight,
        restart,
        report,
        archiveSession,
        unarchiveSession,
        getVisualisationLink,
        markAsRead
    } = useAccountInsightClient();
    const { showConfirmDialog } = useNuxDialog();
    const [searchParameter, setSearchParameter] = useState<SearchParameters>({
        refresh: true,
        list: [],
        text: "",
        selectedStatus: SearchStatus.ALL,
        createdBy: null,
        currentPage: 1,
        sessionsPerPage: 10,
        totalSessions: 0,
        showArchived: false,
    });
    const [visualisationLink, setVisualisationLink] = useState<
        string | undefined
    >(undefined);
    const [creatingCollectionSessionId, setCreatingCollectionSessionId] =
        useState<string | null>(null);
    const [
        creatingCollectionSessionConsentUrl,
        setCreatingCollectionSessionConsentUrl,
    ] = useState<string | null>(null);
    const [creatingCollectionSessionTimer, setCreatingCollectionSessionTimer] =
        useState<NodeJS.Timer | null>(null);
    const isRefreshing = useRef(false); // Ref to track manual updates

    const createConsentRequest = async (
        person: PersonDetails
    ): Promise<void> => {
        return new Promise((resolve, reject) =>
            accountInsightInitiate(
                person.personId,
                person.name,
                person.email,
                person.shouldSendEmail
            )
                .then((value) => {
                    resolve();
                    if (!person.shouldSendEmail)
                        setCreatingCollectionSessionId(value.id);
                })
                .catch((reason) => reject(reason))
        );
    };

    const handleOnSearch = () => {
        const fetchAllAccountInsightSessions = async (
            authenticated: boolean
        ) => {
            if (!authenticated) return;

            const toStatus = (
                selectedStatus: SearchStatus
            ): Status | Status[] | null => {
                switch (selectedStatus) {
                    case SearchStatus.ALL:
                        return null;
                    case SearchStatus.WAITING:
                        return [
                            Status.INITIALIZING,
                            Status.WAITING_FOR_CONSENT,
                            Status.COLLECTION_IN_PROGRESS,
                            Status.FETCHING_INSIGHTS,
                        ];
                    case SearchStatus.COMPLETED:
                        return [Status.INSIGHTS_COLLECTED];
                    case SearchStatus.FAILED:
                        return [Status.ERROR];
                    case SearchStatus.ARCHIVED:
                        return null;
                    default:
                        return null;
                }
            };

            const offset =
                (searchParameter.currentPage - 1) *
                searchParameter.sessionsPerPage;
            try {
                const body = await allAccountInsightSessions(
                    searchParameter.text,
                    searchParameter.sessionsPerPage,
                    offset,
                    toStatus(searchParameter.selectedStatus),
                    searchParameter.selectedStatus === SearchStatus.ARCHIVED,
                    searchParameter.createdBy
                );
                setSearchParameter(updateWithCollectorSessionResponse(body));
            } catch (e) {
                console.error("Failed to communicate with backend!", e);
                showMessage(
                    "Failed to communicate with backend. Try again later!"
                );
            }
        };
        try {
            fetchAllAccountInsightSessions(authenticated).catch((reason) =>
                console.error("Failed to fetch events", reason)
            );
        } catch (e) {
            console.error("Failed to fetch events", e);
        }
    };

    // If not logged in navigate to root /
    useEffect(() => {
        if (!authenticated) navigate("/");
    }, [authenticated]);

    // If changes in search data, preform new search
    useEffect(() => {
        if (authenticated) handleOnSearch();
    }, [
        authenticated,
        searchParameter.text,
        searchParameter.selectedStatus,
        searchParameter.showArchived,
        searchParameter.createdBy,
        searchParameter.currentPage,
        searchParameter.sessionsPerPage,
    ]);

    useEffect(() => {
        if (authenticated && searchParameter.refresh) {
            handleOnSearch();
            setTimeout(() => {
                setSearchParameter(refreshSearch(false));
            }, 1);
        }
    }, [searchParameter.refresh, authenticated]);

    useEffect(() => {
        if (searchParameter.list && searchParameter.list.length > 0) {
            let timeout =
                searchParameter.list
                    .map((searchResult) => searchResult.status)
                    .filter(
                        (value) =>
                            value !== "INSIGHTS_COLLECTED" && value !== "ERROR"
                    ).length > 0
                    ? 5 * 1000 // Wait 10 sec on nothing interesting
                    : 30 * 1000; // Wait 30 sec on interesting activities

            if (!isRefreshing.current) {
                isRefreshing.current = true;
                setTimeout(() => {
                    setSearchParameter(refreshSearch(true));
                    isRefreshing.current = false;
                }, timeout);
            }
        }
    }, [searchParameter.list]);

    useEffect(() => {
        if (
            creatingCollectionSessionId &&
            !creatingCollectionSessionConsentUrl
        ) {
            var ref = setInterval(() => {
                getAccountInsight(creatingCollectionSessionId).then((value) => {
                    if (value.clientRedirectUrl)
                        setCreatingCollectionSessionConsentUrl(
                            value.clientRedirectUrl
                        );
                });
            }, 1000);
            setCreatingCollectionSessionTimer(ref);
        } else if (creatingCollectionSessionTimer) {
            clearInterval(creatingCollectionSessionTimer);
            setCreatingCollectionSessionTimer(null);
        }
    }, [creatingCollectionSessionId, creatingCollectionSessionConsentUrl]);

    if (!authenticated) return <NotLoggedIn />;

    const copyConsentUrlToClipboard = (clientRedirectUrl: string) => {
        if (!clientRedirectUrl) throw new Error("No consent url!");
        if (!navigator.clipboard)
            throw new Error("Browser don't have support for native clipboard!");
        const fetch = async () => {
            await navigator.clipboard.writeText(clientRedirectUrl);
        };
        fetch();
    };

    const showDetails = async (id: string) => {
        const visualisationLink = await getVisualisationLink(id);
        markAsRead(id);
        setVisualisationLink(visualisationLink);
    };

    const restartCollection = async (id: string) => {
        const collectorSession = await restart(id);
        setSearchParameter(refreshSearch(true));
    };

    const showReport = async (id: string) => {
        try {
            const blob = await report(id, i18n.language);
            const blobUrl = URL.createObjectURL(blob);
            window.open(blobUrl, "_blank");
        } catch (error) {
            console.error("Error fetching or opening the PDF:", error);
            showMessage(t("pdfLoadError"));
        }
    };

    const removeSessionClick = async (id: string) => {
        await showConfirmDialog(
            t("confirmRemoveCaption"),
            t("confirmRemoveMessage")
        );
        await removeSession(id);
        setSearchParameter(refreshSearch(true));
    };

    const handleArchiveSession = async (id: string) => {
        try {
            await archiveSession(id);
            setSearchParameter(refreshSearch(true));
        } catch (error) {
            console.error("Failed to archive session", error);
        }
    };

    const handleUnarchiveSession = async (id: string) => {
        try {
            await unarchiveSession(id);
            setSearchParameter(refreshSearch(true));
        } catch (error) {
            console.error("Failed to unarchive session", error);
        }
    };

    const closeCreatingNewAccountInsightSessionDialog = () => {
        setCreatingCollectionSessionId(null);
        setCreatingCollectionSessionConsentUrl(null);
    };

    const onRefresh = () => {
        setSearchParameter(refreshSearch(true));
    };

    const selectSearchStatus = (searchStatus: SearchStatus) => {
        const searchForSelectedStatus1 = searchForSelectedStatus(searchStatus);
        setSearchParameter((prevState) => {
            return searchForSelectedStatus1(prevState) as SearchParameters;
        });
    };

    return (
        <>
            <CreatingNewAccountInsightSessionDialog
                show={!!creatingCollectionSessionId}
                close={() => {
                    closeCreatingNewAccountInsightSessionDialog();
                    refreshSearch(true);
                }}
                onCopyConsentUrlToClipboard={copyConsentUrlToClipboard}
                message={""}
                consentUrl={creatingCollectionSessionConsentUrl}
            />

            <ShowVisualisationLinkDialog
                visualisationLink={visualisationLink}
                setVisualisationLink={setVisualisationLink}
            />

            <SearchBar
                selectedSearchStatus={searchParameter.selectedStatus}
                selectedCreatedBy={searchParameter.createdBy}
                onChangSearchText={(text) =>
                    setSearchParameter(searchForText(text))
                }
                onChangeSearchStatus={selectSearchStatus}
                onChangeCreatedBy={(createdBy) =>
                    setSearchParameter(searchForCreatedBy(createdBy))
                }
                onRefresh={() => onRefresh()}
                createConsentRequest={createConsentRequest}
            />

            {searchParameter.list && searchParameter.list.length === 0 && (
                <div className="mt-4">{t("emptySessionList")}</div>
            )}
            {searchParameter.list && searchParameter.list.length !== 0 && (
                <>
                    <List
                        sx={{
                            width: "100%",
                            bgcolor: "#F7F7F7",
                            paddingTop: "10px",
                            paddingRight: "10px",
                        }}
                    >
                        {searchParameter.list.map((row) => (
                            <ConsentListItem
                                key={row.id}
                                row={row}
                                refresh={searchParameter.refresh}
                                onRemoveSession={removeSessionClick}
                                onShowDetails={showDetails}
                                onRestart={restartCollection}
                                onReport={showReport}
                                onCopyConsentUrlToClipboard={
                                    copyConsentUrlToClipboard
                                }
                                onArchiveSession={handleArchiveSession}
                                onUnarchiveSession={handleUnarchiveSession}
                            />
                        ))}
                    </List>

                    <SearchPagination
                        sessionsPerPage={searchParameter.sessionsPerPage}
                        currentCount={Math.ceil(
                            searchParameter.totalSessions /
                                searchParameter.sessionsPerPage
                        )}
                        currentPage={searchParameter.currentPage}
                        onChangeSessionsPerPage={(sessionsPerPage) =>
                            setSearchParameter(
                                updateSessionsPerPage(Number(sessionsPerPage))
                            )
                        }
                        onChangeCurrentPage={(currentPage) =>
                            setSearchParameter(updateCurrentPage(currentPage))
                        }
                    />
                </>
            )}
        </>
    );
};

export default MainPage;
