import MenuItem from "@mui/material/MenuItem";
import React from "react";

export interface CollectorStatusResponse {
    status: string;
    isArchived: boolean;
}

export interface InitiateCollectorResponse {
    id: string;
    clientRedirectUrl: string;
}

export interface InsightsResponse {
    insights: string;
}

export interface InitiateCollectorRequest {
    personId: string;
    name: string;
    email: string;
}

export interface CollectorSessionResponse {
    list: CollectorSessionBrief[];
    limit: number;
    offset: number;
    total: number;
}

export interface CollectorSessionBrief {
    id: string;
    created: string;
    name: string;
    email: string;
    personId: string;
    clientRedirectUrl: string;
    status: string;
    isArchived: boolean;
    errorMessage: string;
    errorCode: string;
    completed: string;
    isUnread: boolean;
}

export interface CollectorSession {
    id: string;
    created: string;
    name: string;
    email: string;
    personId: string;
    clientRedirectUrl: string;
    status: string;
    errorMessage: string;
    fimentoInsights?: Insights;
    completed: string;
    visualisationLink: string;
    calculatedIncome: string;
    calculatedIncomePeriod: string;
    history: string[];
    isArchived: boolean;
    archivedAt: string;
    initiatorId: string;
    initiatorName: string;
    isUnread: boolean;
}

export interface Insights {
    transactionGraph: TransactionGraph[];
    accountsInfo: AccountsInfo[];
    totalBalance: number;
    fetchedAtInterval: FetchedAtInterval;
    categorisation: { [key: string]: DailyTransaction };
}

export interface AccountsInfo {
    //accountNumber: { [key: string]: string };
    accountNumber: string;
    name: string;
    balance: number;
    interest: string;
    type: string;
    //bank:          { [key: string]: string };
    bank: string;
    closedAt: string;
}

export interface DailyTransaction {
    startDate: Date;
    endDate: Date;
    numberOfTransactions: number;
    posSum: number;
    negSum: number;
    posDet: { [key: string]: AccountEventSummary };
    negDet: { [key: string]: AccountEventSummary };
}

export interface AccountEventSummary {
    sum: number;
    noTrans: number;
    det: AccountEvent[];
}

export interface AccountEvent {
    date: Date;
    amount: number;
    accountNumber: { [key: string]: string };
    bank: { [key: string]: string };
}

export interface FetchedAtInterval {
    min: Date;
    max: Date;
}

export interface TransactionGraph {
    date: Date;
    info: Info;
}

export interface Info {
    balance: number;
    negativeSum: number;
    positiveSum: number;
    positiveTransactions: Transaction[];
    negativeTransactions: Transaction[];
}

export interface Transaction {
    description: string;
    amount: number;
    category: { [key: string]: string };
}

export interface ErrorResponse {
    apiErrorCode: string;
    params: { [key: string]: string };
}

export enum Status {
    INITIALIZING = "INITIALIZING",
    WAITING_FOR_CONSENT = "WAITING_FOR_CONSENT",
    COLLECTION_IN_PROGRESS = "COLLECTION_IN_PROGRESS",
    FETCHING_INSIGHTS = "FETCHING_INSIGHTS",
    INSIGHTS_COLLECTED = "INSIGHTS_COLLECTED",
    ERROR = "ERROR",
}
