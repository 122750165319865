import { useTranslation } from "react-i18next";
import React, { FC, useEffect, useState } from "react";
import {
    Button,
    Card,
    CardContent,
    CircularProgress,
    Collapse,
    Grid,
    IconButton,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Stack,
    TextField,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { SessionStatusProgress } from "./SessionStatusProgress";
import DeleteIcon from "@mui/icons-material/Delete";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import PersonIcon from "@mui/icons-material/Person";
import ArchiveIcon from "@mui/icons-material/Archive";
import UnarchiveIcon from "@mui/icons-material/Unarchive";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import {
    CollectorSessionBrief,
    Insights,
} from "../../../client/AccountInsightsTypes";
import { useAccountInsightClient } from "../../../client/AccountInsightClient";
import Box from "@mui/material/Box";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import {
    ArchivedIcon,
    CompletedIcon,
    ErrorIcon,
    OngoingIcon,
} from "../../../icons/icons";
import InsightsIcon from "@mui/icons-material/Insights";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Tooltip from "@mui/material/Tooltip";

interface ConsentListItemProps {
    row: CollectorSessionBrief;
    refresh: boolean;
    onShowDetails: (id: string) => void;
    onRestart: (id: string) => void;
    onReport: (id: string) => void;
    onCopyConsentUrlToClipboard: (clientRedirectUrl: string) => void;
    onRemoveSession: (id: string) => void;
    onArchiveSession: (id: string) => void;
    onUnarchiveSession: (id: string) => void;
}

class ExtendedInformation {
    history: string[];
    created: string;
    calculatedIncome: string;
    calculatedIncomePeriod: string;
    isArchived: boolean;
    archivedAt: string;
    initiatorId: string;
    initiatorName: string;

    constructor(
        history: string[],
        created: string,
        calculatedIncome: string,
        calculatedIncomePeriod: string,
        isArchived: boolean,
        archivedAt: string,
        initiatorId: string,
        initiatorName: string
    ) {
        this.history = history;
        this.created = created;
        this.calculatedIncome = calculatedIncome;
        this.calculatedIncomePeriod = calculatedIncomePeriod;
        this.isArchived = isArchived;
        this.archivedAt = archivedAt;
        this.initiatorId = initiatorId;
        this.initiatorName = initiatorName;
    }
}

type ExpandedProps = {
    extended: boolean;
    setExtended: (extended: boolean) => void;
};

const Expanded = ({ extended, setExtended }: ExpandedProps) => {
    if (extended)
        return (
            <IconButton
                edge="end"
                aria-label="implode"
                data-testid="do-click-insight-session-implode"
                onClick={() => setExtended(false)}
            >
                <ExpandLess />
            </IconButton>
        );

    return (
        <IconButton
            edge="end"
            aria-label="extend"
            data-testid="do-click-insight-session-extend"
            onClick={() => setExtended(true)}
        >
            <ExpandMore />
        </IconButton>
    );
};

enum ResponsiveButtonSize {
    TINY = "TINY",
    SMALL = "SMALL",
    NORMAL = "NORMAL",
}

type ResponsiveButtonProps = {
    size?: ResponsiveButtonSize;
    caption: string;
    onClick?: () => void;
    icon: React.ReactNode;
    disabled?: boolean;
    dataTestId: string;
};

const ResponsiveButton = ({
    size = ResponsiveButtonSize.NORMAL,
    caption,
    onClick,
    icon,
    disabled = false,
    dataTestId,
}: ResponsiveButtonProps) => {
    if (size === ResponsiveButtonSize.TINY)
        return (
            <Tooltip title={caption}>
                <IconButton
                    color="primary"
                    data-testid={dataTestId}
                    disabled={disabled}
                    onClick={(event) => {
                        if (onClick) onClick();
                    }}
                >
                    {icon}
                </IconButton>
            </Tooltip>
        );

    return (
        <Button
            variant="outlined"
            data-testid={dataTestId}
            disabled={disabled}
            size={size === ResponsiveButtonSize.SMALL ? "small" : "medium"}
            onClick={(event) => {
                if (onClick) onClick();
            }}
            startIcon={icon}
        >
            {caption}
        </Button>
    );
};

type ConsentListItemAction = {
    status: string;
    isUnread : boolean;
    onShowDetails: () => void;
    onCopyConsentUrlToClipboard: () => void;
};

const ConsentListItemAction = ({
    status,
    isUnread,
    onShowDetails,
    onCopyConsentUrlToClipboard,
}: ConsentListItemAction) => {
    const { t } = useTranslation();
    const theme = useTheme();

    const isXs = useMediaQuery(theme.breakpoints.only("xs"));
    const isSm = useMediaQuery(theme.breakpoints.only("sm"));

    const [localIsUnread, setLocalIsUnread] = useState(isUnread);

    if (isXs || isSm) {
        switch (status) {
            case "INSIGHTS_COLLECTED":
                return (
                    <Tooltip title={t("showInsightsButtonCaption")}>
                        <IconButton
                            data-testid="do-click-insight-session-show-insights"
                            color="primary"
                            sx={{
                                backgroundColor: localIsUnread ? "rgba(0, 142, 170, 0.12)" : "inherit"
                            }}
                            onClick={(event) => {
                                onShowDetails()
                                setLocalIsUnread(false);
                            }}
                        >
                            <InsightsIcon />
                        </IconButton>
                    </Tooltip>
                );
            case "WAITING_FOR_CONSENT":
                return (
                    <Tooltip title={t("copyConsentToClipboardButtonCaption")}>
                        <IconButton
                            data-testid="do-click-insight-session-copy-to-clipboard"
                            color="primary"
                            onClick={(event) => onCopyConsentUrlToClipboard()}
                        >
                            <ContentCopyIcon />
                        </IconButton>
                    </Tooltip>
                );
            default:
                return null;
        }
    }

    switch (status) {
        case "INSIGHTS_COLLECTED":
            return (
                <Button
                    onClick={(event) => {
                        onShowDetails()
                        setLocalIsUnread(false);
                    }}
                    startIcon={<InsightsIcon />}
                    sx={{
                        backgroundColor: localIsUnread ? "rgba(0, 142, 170, 0.12)" : "inherit"
                    }}
                >
                    <span style={{ fontWeight: localIsUnread ? '999' : 'normal' }}>
                        {t('showInsightsButtonCaption')}
                    </span>
                </Button>
        );
        case 'WAITING_FOR_CONSENT':
            return (
                <Button
                    //className="m-1"
                    onClick={(event) => onCopyConsentUrlToClipboard()}
                    startIcon={<ContentCopyIcon />}
                >
                    {t("copyConsentToClipboardButtonCaption")}
                </Button>
            );
        default:
            return null;
    }
};

type ExtendedConsentListItemDetailsProps = {
    row: CollectorSessionBrief;
    extendedInformation: ExtendedInformation | undefined;
    onReport: () => void;
    onArchiveSession: () => void;
    onUnarchiveSession: () => void;
    onRestart: () => void;
    onRemoveSession: () => void;
};


const ExtendedConsentListItemDetails = ({
    row,
    extendedInformation,
    onReport,
    onArchiveSession,
    onUnarchiveSession,
    onRemoveSession,
    onRestart,
}: ExtendedConsentListItemDetailsProps) => {
    const { t } = useTranslation();
    const theme = useTheme();

    const isXs = useMediaQuery(theme.breakpoints.only("xs"));
    const isSm = useMediaQuery(theme.breakpoints.only("sm"));
    const isMd = useMediaQuery(theme.breakpoints.only("md"));

    if (!extendedInformation) return null;

    const RestartAndDeleteButtons = () => (
        <>
            <ResponsiveButton
                caption={t("restartButtonCaption")}
                dataTestId="do-restart-session"
                disabled={row.completed !== "true" && row.status !== "ERROR"}
                icon={<RestartAltIcon />}
                onClick={() => onRestart()}
                size={ResponsiveButtonSize.NORMAL}
            />
            <ResponsiveButton
                caption={t("delete")}
                icon={<DeleteIcon />}
                onClick={() => onRemoveSession()}
                dataTestId="do-click-insight-session-remove"
                size={ResponsiveButtonSize.NORMAL}
            />
        </>
    );

    return (
        <Card variant="outlined">
            <CardContent>
                <Stack direction="column" spacing={2}>
                    <TextWithCaption caption={t("internalId")} text={row.id} />

                    <TextWithCaption
                        caption={t("created")}
                        text={extendedInformation.created}
                    />

                    <TextWithCaption
                        caption={t("createdBy")}
                        text={extendedInformation.initiatorName}
                    />

                    <TextWithCaption
                        caption={t("ARCHIVED")}
                        text={extendedInformation.archivedAt}
                    />

                    <TextWithCaption
                        caption={t("personEmail")}
                        text={row.email}
                    />

                    <TextWithCaption
                        caption="Internal Error Message (in English)"
                        text={row.errorMessage}
                    />
                </Stack>
                <Box mt={4} />

                <Box display="flex" flexDirection="row" gap={2}>
                    <Stack direction="column" spacing={2}>
                        <ResponsiveButton
                            dataTestId="do-print-report-session"
                            caption={t("reportButtonCaption")}
                            disabled={
                                row.status !== 'INSIGHTS_COLLECTED'
                            }
                            icon={<PictureAsPdfIcon />}
                            onClick={() => onReport()}
                            size={ResponsiveButtonSize.NORMAL}
                        />
                        {!extendedInformation.isArchived && (
                            <ResponsiveButton
                                dataTestId="do-archive-session"
                                caption={t("archive")}
                                icon={<ArchiveIcon />}
                                disabled={
                                    row.completed !== "true" &&
                                    row.status !== "ERROR"
                                }
                                onClick={() => onArchiveSession()}
                                size={ResponsiveButtonSize.NORMAL}
                            />
                        )}
                        {extendedInformation.isArchived && (
                            <ResponsiveButton
                                dataTestId="do-unarchive-session"
                                caption={t("unarchive")}
                                icon={<UnarchiveIcon />}
                                onClick={() => onUnarchiveSession()}
                                size={ResponsiveButtonSize.NORMAL}
                            />
                        )}
                        {isMd && <RestartAndDeleteButtons />}
                    </Stack>
                    {!isMd && (
                        <Stack direction="column" spacing={2}>
                            <RestartAndDeleteButtons />
                        </Stack>
                    )}
                </Box>
            </CardContent>
        </Card>
    );
};

type TextWithCaptionProps = {
    text?: string;
    caption: string;
};

const TextWithCaption = ({ text, caption }: TextWithCaptionProps) => {
    if (!text) return null;

    return (
        <Box
            sx={{
                display: "inline-block",
                border: "1px solid lightgrey",
                borderRadius: "5px",
                position: "relative",
                padding: "8px",
                marginTop: "24px", // Space from the top to avoid overlapping
            }}
        >
            <Typography
                variant="caption"
                sx={{
                    position: "absolute",
                    top: "-12px", // Adjusts the caption above the border
                    left: "16px", // Aligns the caption with the box's padding
                    backgroundColor: "white", // Matches the background to cover border line
                    padding: "0 4px", // Adds padding around the caption
                }}
            >
                {caption}
            </Typography>
            <Typography>{text}</Typography>
        </Box>
    );
};

type ExtendedConsentListItemIncomeDetailsProps = {
    extendedInformation: ExtendedInformation | undefined;
};

const ExtendedConsentListItemIncomeDetails = ({
    extendedInformation,
}: ExtendedConsentListItemIncomeDetailsProps) => {
    const { t } = useTranslation();

    if (!extendedInformation) return null;

    if (
        !extendedInformation.calculatedIncome &&
        !extendedInformation.calculatedIncomePeriod
    )
        return null;

    return (
        <Card variant="outlined">
            <CardContent>
                <Stack direction="column" spacing={2}>
                    <TextWithCaption
                        caption={t("calculatedIncome")}
                        text={extendedInformation.calculatedIncome}
                    />
                    <TextWithCaption
                        caption={t("calculatedIncomePeriod")}
                        text={extendedInformation.calculatedIncomePeriod}
                    />
                </Stack>
            </CardContent>
        </Card>
    );
};

type ExtendedConsentListItemHistory = {
    extendedInformation: ExtendedInformation | undefined;
};

const ExtendedConsentListItemHistory = ({
    extendedInformation,
}: ExtendedConsentListItemHistory) => {
    const { t } = useTranslation();

    if (!extendedInformation) return null;

    return (
        <Card variant="outlined">
            <CardContent>
                <Typography variant="overline">{t("history")}</Typography>
                <Typography variant="body1">
                    {extendedInformation.history.map((hist) => {
                        return (
                            <Typography key={hist}>
                                {hist}
                                <br />
                            </Typography>
                        );
                    })}
                </Typography>
            </CardContent>
        </Card>
    );
};

type ExtendedConsentListItemProps = {
    row: CollectorSessionBrief;
    extendedInformation: ExtendedInformation | undefined;
    onReport: () => void;
    onArchiveSession: () => void;
    onUnarchiveSession: () => void;
    onRestart: () => void;
    onRemoveSession: () => void;
};

const ExtendedConsentListItem = ({
    row,
    extendedInformation,
    onReport,
    onArchiveSession,
    onUnarchiveSession,
    onRestart,
    onRemoveSession,
}: ExtendedConsentListItemProps) => {
    const { t } = useTranslation();
    const theme = useTheme();

    const isXs = useMediaQuery(theme.breakpoints.only("xs"));
    const isSm = useMediaQuery(theme.breakpoints.only("sm"));

    if (!extendedInformation)
        return (
            <Box
                sx={{
                    backgroundColor: "white",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "30px",
                }}
            >
                <CircularProgress />
            </Box>
        );

    if (isXs || isSm) {
        return (
            <Box
                sx={{
                    background: "white",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    paddingLeft: "20px",
                    paddingRight: "20px",
                    borderBottomLeftRadius: "5px",
                    borderBottomRightRadius: "5px",
                }}
            >
                <Stack direction="column" spacing={1}>
                    <ExtendedConsentListItemDetails
                        row={row}
                        extendedInformation={extendedInformation}
                        onReport={onReport}
                        onArchiveSession={onArchiveSession}
                        onUnarchiveSession={onUnarchiveSession}
                        onRestart={onRestart}
                        onRemoveSession={onRemoveSession}
                    />
                    <ExtendedConsentListItemIncomeDetails
                        extendedInformation={extendedInformation}
                    />
                    <ExtendedConsentListItemHistory
                        extendedInformation={extendedInformation}
                    />
                </Stack>
            </Box>
        );
    }

    return (
        <Grid
            container
            spacing={1}
            style={{
                background: "white",
                marginLeft: "1px",
                width: "100%",
                paddingTop: "5px",
                paddingBottom: "20px",
                paddingLeft: "20px",
                paddingRight: "20px",
                borderBottomLeftRadius: "5px",
                borderBottomRightRadius: "5px",
            }}
        >
            <Grid item xs={12} sm={4}>
                <ExtendedConsentListItemDetails
                    row={row}
                    extendedInformation={extendedInformation}
                    onReport={onReport}
                    onArchiveSession={onArchiveSession}
                    onUnarchiveSession={onUnarchiveSession}
                    onRestart={onRestart}
                    onRemoveSession={onRemoveSession}
                />
            </Grid>
            <Grid item xs={12} sm={8} style={{ paddingRight: "10px" }}>
                <Stack direction="column" spacing={1}>
                    <ExtendedConsentListItemIncomeDetails
                        extendedInformation={extendedInformation}
                    />
                    <ExtendedConsentListItemHistory
                        extendedInformation={extendedInformation}
                    />
                </Stack>
            </Grid>
        </Grid>
    );
};

export const ConsentListItem = ({
    row,
    refresh,
    onShowDetails,
    onRestart,
    onReport,
    onCopyConsentUrlToClipboard,
    onRemoveSession,
    onArchiveSession,
    onUnarchiveSession,
}: ConsentListItemProps) => {
    const { t, i18n } = useTranslation();
    const { getAccountInsight } = useAccountInsightClient();
    const [extended, setExtended] = useState<boolean>(false);
    const [extendedInformation, setExtendedInformation] = useState<
        ExtendedInformation | undefined
    >(undefined);
    const theme = useTheme();

    const isXs = useMediaQuery(theme.breakpoints.only("xs"));
    const isSm = useMediaQuery(theme.breakpoints.only("sm"));

    useEffect(() => {
        if (refresh && extended) {
            load();
        }
    }, [refresh, extended]);

    useEffect(() => {
        const onLanguageChanged = (lng: string) => {
            if (extended) load();
        };

        i18n.on("languageChanged", onLanguageChanged);

        return () => {
            i18n.off("languageChanged", onLanguageChanged); // Clean up listener
        };
    }, [i18n]);

    const load = async () => {
        try {
            let collectorSession = await getAccountInsight(row.id);
            setExtendedInformation(
                new ExtendedInformation(
                    collectorSession.history.map((history) => {
                        return history
                            .split(" ")
                            .map((value) => {
                                let statusStr = statusToString(value);
                                if (statusStr !== value) return t(statusStr);
                                return value;
                            })
                            .join(" ");
                    }),
                    collectorSession.created,
                    collectorSession.calculatedIncome,
                    collectorSession.calculatedIncomePeriod,
                    collectorSession.isArchived,
                    collectorSession.archivedAt,
                    collectorSession.initiatorId,
                    collectorSession.initiatorName
                )
            );
        } catch (e) {
            console.error("Failed to get account insights for " + row.id, e);
        }
    };

    const extendPanel = async () => {
        setExtended(true);
        await load();
    };

    const implodePanel = async () => {
        setExtended(false);
        setExtendedInformation(undefined);
    };

    return (
        <div data-testid="consent-list-item">
            <ListItem
                sx={{
                    marginTop: "5px",
                    borderRadius: "5px",
                    backgroundColor: "white",
                }}
                alignItems="flex-start"
                secondaryAction={
                    <>
                        <ConsentListItemAction
                            status={row.status}
                            isUnread={row.isUnread}
                            onShowDetails={() => onShowDetails(row.id)}
                            onCopyConsentUrlToClipboard={() =>
                                onCopyConsentUrlToClipboard(
                                    row.clientRedirectUrl
                                )
                            }
                        />
                        <Expanded
                            extended={extended}
                            setExtended={(changeExtended) =>
                                changeExtended ? extendPanel() : implodePanel()
                            }
                        />
                    </>
                }
            >
                <ListItemAvatar>
                    <PersonIcon />
                </ListItemAvatar>
                <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={4}>
                        <ListItemText
                            primary={row.name}
                            secondary={row.personId}
                        />
                    </Grid>
                    <Grid item xs={8}>
                        <ListItemText
                            primary={
                                <SessionStatus
                                    status={row.status}
                                    errorCode={row.errorCode}
                                    archived={row.isArchived}
                                />
                            }
                            secondary={
                                <SessionDetailsStatus
                                    status={row.status}
                                    errorCode={row.errorCode}
                                />
                            }
                        />
                    </Grid>
                </Grid>
            </ListItem>
            <Collapse in={extended} timeout="auto" unmountOnExit>
                <ExtendedConsentListItem
                    row={row}
                    extendedInformation={extendedInformation}
                    onReport={() => onReport(row.id)}
                    onArchiveSession={() => onArchiveSession(row.id)}
                    onUnarchiveSession={() => onUnarchiveSession(row.id)}
                    onRestart={() => onRestart(row.id)}
                    onRemoveSession={() => onRemoveSession(row.id)}
                />
            </Collapse>
        </div>
    );
};

function calculateIcome(insights: Insights): number {
    console.log("calculateIcome", JSON.stringify(insights));
    try {
        return 0;
        /*return insights.categorisation.INCOME.subCategories
                    .filter(sub => sub.name==='income')
                    .map(sub => )
                    .posSum*/
    } catch (e) {
        console.log("calculateIcome", JSON.stringify(insights), e);
        return 0;
    }
}

const statusToString = (status: string) => {
    switch (status) {
        case "INITIALIZING":
            return "initializing";
        case "INSIGHTS_COLLECTED":
            return "completed";
        case "FETCHING_INSIGHTS":
            return "fetchingInsights";
        case "COLLECTION_IN_PROGRESS":
            return "collectingAndCalculating";
        case "WAITING_FOR_CONSENT":
            return "waitingForConsent";
        case "ERROR":
            return "failed";
        case "ARCHIVED":
            return "archived";
        case "UNARCHIVED":
            return "unarchived";
        case "CREATED":
            return "historyCreated";
        case "EMAIL_SENT":
            return "emailSent";
        case "CONSENT_LINK_OPENED":
            return "consentLinkOpened";
        case "CUSTOMER_APPROVED_CONSENT":
            return "customerApprovedConsent";
        case "CATEGORIZATION_STARTED":
            return "categorizationStarted";
        case "CATEGORIZATION_DONE":
            return "categorizationDone";
        case "INSIGHTS_STARTED":
            return "insightsStarted";
        case "CUSTOMER_DENIED_CONSENT":
            return "customerDeniedConsent";
        case "CUSTOMER_DENIED_CONSENT_RESTART":
            return "customerDeniedConsentRestart";
        case "CONSENT_RESTART":
            return "consentRestart";
        case "COMPLETED":
            return "processCompleted";

        default:
            return status;
    }
};

interface StatusProps {
    status?: string;
    errorCode?: string;
    archived: boolean;
}

export const SessionStatus: FC<StatusProps> = ({
    status,
    errorCode,
    archived,
}) => {
    const { t } = useTranslation();

    if (!status) return null;
    if (archived)
        return (
            <>
                <ArchivedIcon size="small" />
                &nbsp;{t("archived")}
            </>
        );

    switch (status) {
        case "INITIALIZING":
        case "WAITING_FOR_CONSENT":
        case "COLLECTION_IN_PROGRESS":
        case "FETCHING_INSIGHTS":
            return (
                <>
                    <OngoingIcon size="small" />
                    &nbsp;{t("ongoing")}
                </>
            );
        case "INSIGHTS_COLLECTED":
        case "COMPLETED":
            return (
                <>
                    <CompletedIcon size="small" color="primary" />
                    &nbsp;{t("processCompleted")}
                </>
            );
        case "ERROR":
            return (
                <>
                    <ErrorIcon size="small" />
                    &nbsp;{t("failed")}
                </>
            );
        default:
            return <>{status}</>;
    }
};

interface StatusDetailsProps {
    status?: string;
    errorCode?: string;
}

export const SessionDetailsStatus: FC<StatusDetailsProps> = ({
    status,
    errorCode,
}) => {
    const { t } = useTranslation();

    if (!status) return null;

    if (status === "ERROR") {
        if (errorCode === "CUSTOMER_DENIED_CONSENT")
            return <>{t("customerDeniedConsent")}</>;
        if (errorCode === "TIMED_OUT_WAITING")
            return <>{t("timeoutWaiting")}</>;
    }

    return <>{t(statusToString(status))}</>;
};
